<template>
  <div>
    <h1>Settings</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Settings',
  components: {},
  methods: {},
});
</script>

<style lang="scss" scoped></style>
